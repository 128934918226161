/* eslint-disable eqeqeq */
import * as React from 'react';

import { Heading, INodeProps } from '@msdyn365-commerce-modules/utilities';
import { getUrlSync } from '@msdyn365-commerce/core';
import { OrgUnitLocation, ProductDimension } from '@msdyn365-commerce/retail-proxy';

import { ICcbcubuyboxProps } from '../ccbcubuybox.props.autogenerated';
import { IBuyboxCallbacks, IBuyboxState } from '../ccbcubuybox';
import { ICcbcubuyboxData } from '../ccbcubuybox.data';
import { BuyboxErrorBlock } from './ccbcubuybox-small-components';
import { searchOrgUnitLocationsAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/OrgUnitsDataActions.g';

import { getConfigureErrors } from '../utilities/error-utilities';

export interface IFindInStoreFailureResult {
    missingDimensions?: ProductDimension[];
}

export interface IBuyboxFindInStoreViewProps {
    storeSelector?: React.ReactNode;
    heading?: React.ReactNode;
    description?: React.ReactNode;
    errors?: React.ReactNode;
    button?: React.ReactNode;

    ContainerProps: INodeProps;

    openFindInStoreDialog(): Promise<void>;
}

export function getBuyboxFindInStore(props: ICcbcubuyboxProps<ICcbcubuyboxData>, state: IBuyboxState, callbacks: IBuyboxCallbacks, orgUnitNumber: string): IBuyboxFindInStoreViewProps | undefined {
    const {
        data: {
            product: { result: product },
            storeSelectorStateManager: { result: storeSelectorStateManager },
            cart: { result: cart }
        },
        slots: {
            storeSelector
        },
        resources,
        context: {
            request: {
            }
        }
    } = props;

    const
        {
            errorState: {
                configureErrors,
                quantityError,
                otherError,
                errorHost
            }
        } = state;

    const onClick = () => pickupInStoreOnClick(props, state, callbacks, orgUnitNumber);

    if (!product || !storeSelectorStateManager || storeSelector.length === 0) {
        return undefined;
    }

    return {
        ContainerProps: {
            className: 'ms-buybox__find-in-store'
        },

        storeSelector: storeSelector[0],

        openFindInStoreDialog: onClick,

        heading: (
            <Heading
                className='ms-buybox__find-in-store-heading'
                headingTag='h4'
                text=''
            />
        ),

        description: (
            <p className='ms-buybox__find-in-store-description'></p>
        ),

        errors: (
            <BuyboxErrorBlock
                configureErrors={configureErrors}
                quantityError={quantityError}
                otherError={otherError}
                resources={resources}
                showError={errorHost === 'FINDINSTORE'}
            />
        ),

        button: (
            <button
                className='ms-buybox__find-in-store-button'
                onClick={onClick}
                color='secondary'
                aria-label={resources.findInStoreLinkText}
                disabled={cart === undefined}
            >
                {resources.findInStoreLinkText}
            </button>
        )
    };
}

async function pickupInStoreOnClick(props: ICcbcubuyboxProps<ICcbcubuyboxData>, state: IBuyboxState, callbacks: IBuyboxCallbacks, orgUnitNumber: string): Promise<void> {
    const {
        data: {
            storeSelectorStateManager: { result: storeSelectorStateManager },
            cart: { result: cart }
        },
        context,
        resources
    } = props;

    const {
        selectedProduct,
        quantity
    } = state;
    const productDim = props.data.productDimensions.result ? props.data.productDimensions.result[0] : undefined;

    if(productDim && Object.keys(state.selectedDimensions).length == 0) {
        var dtype = props.data.productDimensions.result? props.data.productDimensions.result[0].DimensionTypeValue: 3;

        var a :IFindInStoreFailureResult = {
            missingDimensions:[{
                DimensionTypeValue: dtype
            }]
        };

        callbacks.updateErrorState({
            configureErrors: getConfigureErrors(a.missingDimensions, resources)
        });
    }

    let dataProduct = props.data.product.result;

    if (selectedProduct) {
        dataProduct = (await selectedProduct) || dataProduct;
    }

    if (!dataProduct || !storeSelectorStateManager) {
        return;
    }

    const product = dataProduct;

    const behavior = props.context?.app?.config?.addToCartBehavior;
    const orgUnit = await searchOrgUnitLocationsAsync({ callerContext: context.actionContext }, { OrgUnitNumber: orgUnitNumber });
    const orgUnitLocation = getOrgUnitLocation(orgUnit && orgUnit[0]);

    return cart && cart.addProductToCart({ product: product, count: quantity, location: orgUnitLocation })
        .then(result => {
            if (result.status === 'FAILED' && result.substatus === 'MAXQUANTITY') {
                props.telemetry.debug('Unable to add product to cart');
            } else {
                if ((behavior === undefined || behavior === 'goToCart')) {
                    const navigationUrl = getUrlSync('cart', context.actionContext);

                    if (result.status === 'SUCCESS' && navigationUrl) {
                        window.location.assign(navigationUrl);
                    }
                } else if (behavior === 'showModal') {
                    callbacks.updateErrorState({
                        configureErrors: {}
                    });
                }
            }
        }).catch((error: Error) => {
            if (props.telemetry) {
                props.telemetry.exception(error);
                props.telemetry.debug('Unable to add product to cart');
            }
            return;
        });
}

export function getOrgUnitLocation(orgUnitLocation?: OrgUnitLocation): OrgUnitLocation {
    return {
        OrgUnitNumber: orgUnitLocation && orgUnitLocation.OrgUnitNumber,
        PostalAddressId: orgUnitLocation && orgUnitLocation.PostalAddressId,
        OrgUnitName: orgUnitLocation && orgUnitLocation.OrgUnitName,
        Address: orgUnitLocation && orgUnitLocation.Address,
        Street: orgUnitLocation && orgUnitLocation.Street,
        StreetNumber: orgUnitLocation && orgUnitLocation.StreetNumber,
        City: orgUnitLocation && orgUnitLocation.City,
        DistrictName: orgUnitLocation && orgUnitLocation.DistrictName,
        BuildingCompliment: orgUnitLocation && orgUnitLocation.BuildingCompliment,
        Postbox: orgUnitLocation && orgUnitLocation.Postbox,
        Country: orgUnitLocation && orgUnitLocation.Country,
        Zip: orgUnitLocation && orgUnitLocation.Zip,
        County: orgUnitLocation && orgUnitLocation.County,
        CountyName: orgUnitLocation && orgUnitLocation.CountyName,
        State: orgUnitLocation && orgUnitLocation.State,
        StateName: orgUnitLocation && orgUnitLocation.StateName
    };
}
